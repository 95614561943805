import React from 'react';
import Tile from './Tile';
import projectDataJSON from './projectData';


export default function Projects() {
    // Fetch Project Data
    const projectData = projectDataJSON;

    // Calculate CategoryCounts
    const categoryCounts = projectData.reduce((acc, project) => {
        const category = project.category;
        const featured_project = project.featured_project;

        if (!acc["Featured"]) {
            acc["Featured"] = 0;
        }
        if (featured_project === true) {
            acc["Featured"]++;
        }

        if (acc[category]) {
            acc[category]++;
        } else {
            acc[category] = 1;
        }
        return acc;
    }, {});




    // Project Filters
    const [projectCategory, setProjectCategory] = React.useState("Featured");
    const [selectedFilter, setSelectedFilter] = React.useState("Featured");


    // function to handle project filters on select
    function handleProjectFilters(event) {
        setProjectCategory(event.target.value);
        setSelectedFilter(event.target.value);
    }

    // Generate Filter Buttons
    const filters_list = Object.entries(categoryCounts).map(([key, val], index) => (
        <li key={index}>
            <button
                className={`projects--filter-btn ${selectedFilter === key ? 'selected' : ''}`}
                onClick={handleProjectFilters}
                value={key}
            >
                {key}<sup className='highlight'>{val}</sup>
            </button>
        </li>
    ))



    // Make project tiles
    const projectTiles = projectCategory === "Featured"
        ? projectData
            .filter(project => project.featured_project === true)
            .map(project => (
                <Tile
                    key={project.id}
                    {...project}
                />
            ))
        : projectData
            .filter(project => project.category === projectCategory)
            .map(project => (
                <Tile
                    key={project.id}
                    {...project}
                />
            ));

    return (
        <section id='projects'>
            <h2 className='heading-index index-3'>Personal Projects I've built</h2>
            <div className='projects--filters'>
                <ul>
                    <li className='flash'>Filters: </li>
                    {filters_list}
                </ul>
            </div>
            <div className='projects--cards'>
                {projectTiles}
            </div>
        </section>
    )
}