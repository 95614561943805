import React from 'react';

export default function Tile(props) {
    const tags = props.tags.map((tag, index) => (
        <li key={index}>{tag}</li>
    ))

    // Conditionally render the anchor tag if demo_link is not empty
    const projectTile = props.demo_link ? (
        <a href={props.demo_link} target='_blank' rel='noreferrer' className='project--tile'>
            <div className='project--tile-header'>
                <div className='project--tile-top'>
                    <i class="fa-solid fa-diagram-project"></i>
                    <div className='project--tile-nav-links'>
                        {props.github_link && <a href={props.github_link} target='_blank' rel='noreferrer'><i class="fa-brands fa-github"></i></a>}
                        {props.demo_link && <a href={props.demo_link} target='_blank' rel='noreferrer'><i class="fa-solid fa-arrow-up-right-from-square"></i></a>}
                    </div>
                </div>
                <h3>{props.title}</h3>
                <p className='project--tile-description'>{props.description}</p>
            </div>
            <div className='project--tile-footer skill-tags'>
                <ul>
                    {tags}
                </ul>
            </div>
        </a>
    ) : (
        <div className='project--tile'>
            <div className='project--tile-header'>
                <div className='project--tile-top'>
                    <i class="fa-solid fa-diagram-project"></i>
                    <div className='project--tile-nav-links'>
                        {props.github_link && <a href={props.github_link} target='_blank' rel='noreferrer'><i class="fa-brands fa-github"></i></a>}
                        {props.demo_link && <a href={props.demo_link} target='_blank' rel='noreferrer'><i class="fa-solid fa-arrow-up-right-from-square"></i></a>}
                    </div>
                </div>
                <h3>{props.title}</h3>
                <p className='project--tile-description'>{props.description}</p>
            </div>
            <div className='project--tile-footer skill-tags'>
                <ul>
                    {tags}
                </ul>
            </div>
        </div>
        // Render without anchor tag if demo_link is empty
    );

    return projectTile;

}