import React, { useState, useEffect } from "react";

export default function NavBar() {
    const [activeLink, setActiveLink] = useState("#home");

    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll("section");
            const scrollPosition = window.scrollY;

            sections.forEach((section) => {
                const sectionTop = section.offsetTop;
                const sectionHeight = section.clientHeight;

                if (
                    scrollPosition >= sectionTop - 50 &&
                    scrollPosition < sectionTop + sectionHeight - 50
                ) {
                    setActiveLink(`#${section.id}`);
                }
            });
        };

        window.addEventListener("scroll", handleScroll);

        // Cleanup
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleClick = (target) => {
        document.querySelector(target).scrollIntoView({ behavior: "smooth" });
        setActiveLink(target);
    };

    return (
        <nav>
            <h3>
                <a
                    className={`navbar-section-list btn ${activeLink === "#home" ? "active" : ""}`}
                    href="#home"
                    onClick={() => handleClick("#home")}
                >
                    <sup className="highlight"> 01 </sup>&nbsp;// Home
                </a>
            </h3>
            <h3>
                <a
                    className={`navbar-section-list btn ${activeLink === "#expertise" ? "active" : ""}`}
                    href="#expertise"
                    onClick={() => handleClick("#expertise")}
                >
                    <sup className="highlight"> 02 </sup>&nbsp;// Expertise
                </a>
            </h3>
            <h3>
                <a
                    className={`navbar-section-list btn ${activeLink === "#projects" ? "active" : ""}`}
                    href="#projects"
                    onClick={() => handleClick("#projects")}
                >
                    <sup className="highlight"> 03 </sup>&nbsp;// Projects
                </a>
            </h3>
            <h3>
                <a
                    className={`navbar-section-list btn ${activeLink === "#experience" ? "active" : ""}`}
                    href="#experience"
                    onClick={() => handleClick("#experience")}
                >
                    <sup className="highlight"> 04 </sup>&nbsp;// Experience
                </a>
            </h3>
            <h3>
                <a
                    className={`navbar-section-list btn ${activeLink === "#contact" ? "active" : ""}`}
                    href="#contact"
                    onClick={() => handleClick("#contact")}
                >
                    <sup className="highlight"> 05 </sup>&nbsp;// Contact
                </a>
            </h3>

            <h3> <a href="/Hanumanthu Resume.pdf" target="_blank" className="navbar-section-list btn resume">Resume</a> </h3>
            <div className="socialList-icons">
                <ul>
                    <li><a href="https://www.github.com/hsmgowtham" target='_blank' rel='noreferrer'><i class="fa-brands fa-github"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/hsmgowtham" target='_blank' rel='noreferrer'><i class="fa-brands fa-linkedin"></i></a></li>
                </ul>
            </div>
            <div className="vertical-email">
                <div className="vertical-email-wrapper">
                    <a href="mailto:gowthamhsm@gmail.com">gowthamhsm@gmail.com</a>
                </div>
            </div>
        </nav>
    );
}
