import React from 'react';


export default function Contact() {
    return (
        <section id='contact'>
            <p className='heading-index index-5'>What's next?</p>
            <h2>Get in Touch</h2>
            <p>Interested in working together or just want to chat? Drop me a line and I'll get back to you as soon as possible.</p>
            <p> I'm always open to new opportunities and happy conversations!</p>
            <button className='btn contact'><a href='mailto:gowthamhsm@gmail.com'>Say Hello</a></button>
        </section>
    )
}