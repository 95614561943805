import React from 'react';


export default function Expertise() {
    return (
        <section id="expertise">
            <h2 className='heading-index index-2'>My Expertise </h2>
            <div className='expertise--box'>
                <div className='expertise--column'>
                    <div className='expertise--colum-header'>
                        <i class="fas fa-laptop-code"></i>
                        <h3 className='highlight-underline'>Software Engineering</h3>
                    </div>
                    <div className='expertise--column-content-container'>
                        <div className='expertise--column-content'>
                            <p>Experienced in Python, proficient in both functional and OOP paradigms, with expertise in frameworks like Django and Flask.</p>
                        </div>
                    </div>
                    {/* <div className="horizontal-line">
                    </div>
                    <div className="expertise-skills skill-tags">
                        <ul>
                            <li>Django</li>
                            <li>Django Rest Framework</li>
                            <li>Rest API</li>
                            <li>Test Driven Development</li>
                            <li>Flask</li>
                            <li>Authentication</li>
                            <li>PostgreSQL</li>
                            <li>MongoDB</li>
                            <li>AWS</li>
                            <li>Celery</li>
                            <li>Docker</li>
                            <li>Kubernetes</li>
                            <li>Agile Development</li>
                        </ul>
                    </div> */}
                </div>
                <div className='expertise--column'>
                    <div className='expertise--colum-header'>
                        <i class="fas fa-database"></i>
                        <h3 className='highlight-underline'>Data Engineering</h3>
                    </div>
                    <div className='expertise--column-content-container'>
                        <div className='expertise--column-content'>
                            <p>Passionate about optimizing data workflows with proficiency in PySpark, Airflow, and Kafka for efficient data processing and analysis.</p>
                        </div>
                    </div>
                </div>
                <div className='expertise--column'>
                    <div className='expertise--colum-header'>
                        <i class="fab fa-react"></i>
                        <h3 className='highlight-underline'>Frontend Development (Occasional)</h3>
                    </div>
                    <div className='expertise--column-content-container'>
                        <div className='expertise--column-content'>
                            <p>Capable of designing user interfaces when required, proficient in HTML, CSS, JavaScript, and ReactJS for frontend development.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}