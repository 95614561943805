const projectData = [
    {
        "id": "1",
        "title": "Data Modeling and Analysis with Star Schema: DVD Rental",
        "category": "Data Engineering",
        "description": "The implementation of a star schema for efficient data modeling, analysis, and Providing substantial advantages in terms of query speed and efficiency",
        "tags": ["PostgreSQL", "DB Design"],
        "github_link": "https://github.com/hsmgowtham/Data-Engineering-Analytics-and-Visualization-Projects/tree/main/Data%20Modeling%20and%20Analysis%20using%20Star%20Schema%3A%20DVD%20Rental",
        "demo_link": "",
        "featured_project": true,
        "archive": false,
    },
    {
        "id": "2",
        "title": "Nota",
        "category": "Django Rest API",
        "description": "A simple list sharing API built with Python and Django Rest Framework",
        "tags": ["Python", "Django", "DRF", "JWT Auth", "Swagger"],
        "github_link": "https://github.com/hsmgowtham/NOTA",
        "demo_link": "",
        "featured_project": true,
        "archive": false,
    },
    {
        "id": "3",
        "title": "Meme Generator",
        "category": "ReactJS",
        "description": "Generates random memes and allows you to add top and bottom text to them. It is built using React JS.",
        "tags": ["ReactJS", "Javascript", "HTML", "CSS"],
        "github_link": "https://github.com/hsmgowtham/NOTA",
        "demo_link": "https://hsmgowtham.github.io/Meme-Generator/",
        "featured_project": false,
        "archive": false,
    },
    {
        "id": "4",
        "title": "Instituition Project Management System",
        "category": "Django Rest API",
        "description": "This system helps us to fetch details from API specific to institutions, Faculty, Students, and Projects",
        "tags": ["Python", "Django", "DRF", "Swagger"],
        "github_link": "https://github.com/hsmgowtham/Institution-Project-Management-System",
        "demo_link": "",
        "featured_project": false,
        "archive": false,
    },
    {
        "id": "5",
        "title": "Poll App",
        "category": "Django Rest API",
        "description": "A Simple Poll app that lists available polls, take votes, show results.",
        "tags": ["Python", "Django", "DRF"],
        "github_link": "https://github.com/hsmgowtham/Poll-App-DRF",
        "demo_link": "",
        "featured_project": false,
        "archive": false,
    },
    {
        "id": "6",
        "title": "AirBNB Experiences Clone",
        "category": "ReactJS",
        "description": "A clone of the AirBnB Experiences feature, built using React.js",
        "tags": ["ReactJS", "Javascript", "HTML", "CSS"],
        "github_link": "https://github.com/hsmgowtham/AirBnb-Experiences",
        "demo_link": "https://hsmgowtham.github.io/AirBnb-Experiences/",
        "featured_project": false,
        "archive": false,
    },
    {
        "id": "7",
        "title": "Digital Business Card",
        "category": "ReactJS",
        "description": "A Digital Business Card made using React JS",
        "tags": ["ReactJS", "Javascript", "HTML", "CSS"],
        "github_link": "https://github.com/hsmgowtham/Digital-Business-Card",
        "demo_link": "https://hsmgowtham.github.io/Digital-Business-Card/",
        "featured_project": false,
        "archive": false,
    },
    {
        "id": "8",
        "title": "Social Media App Database",
        "category": "Data Engineering",
        "description": "Build a PostgreSQL Database for Social Media: Manage user profiles, friendships, posts, likes, and comments efficiently.",
        "tags": ["PostgreSQL", "DB Design"],
        "github_link": "https://github.com/hsmgowtham/Data-Engineering-Analytics-and-Visualization-Projects/tree/main/SQL%20Database%20Designs/Social%20Media%20App%20DB%20Design%20Project",
        "demo_link": "",
        "featured_project": true,
        "archive": false,
    },
    {
        "id": "9",
        "title": "Personal Portfolio Website",
        "category": "ReactJS",
        "description": "Portfolio Website made using React JS",
        "tags": ["ReactJS", "Javascript", "HTML", "CSS"],
        "github_link": "",
        "demo_link": "",
        "featured_project": true,
        "archive": false,
    },
    {
        "id": "10",
        "title": "TheBigDataPy",
        "category": "Full Stack",
        "description": "Designed and developed TheBigDataPy, a fully functional blog website featuring articles on data engineering, Django, SQL, and related topics.",
        "tags": ["Django", "DRF", "TDD", "ReactJS", "Docker", "AWS EC2", "AWS RDS", "Cloudflare R2", "PostgreSQL", "Javascript", "HTML", "CSS"],
        "github_link": "",
        "demo_link": "https://www.thebigdatapy.com/",
        "featured_project": true,
        "archive": false,
    }


]

export default projectData;