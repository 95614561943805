import React from 'react';
import experienceData from './ExperienceData';

export default function Experience() {
    return (
        <section id='experience'>
            <h2 className='heading-index index-4'>Where I've Worked</h2>
            <div className='experience--container'>
                {experienceData.map((experience) => (
                    <a key={experience.id} className='experience--item' rel="noreferrer" href={experience.website} target='_blank'>
                        <div className='experience--timeline'>
                            {experience.timeline}
                        </div>
                        <div className='experience--details'>
                            <h3 className='experience--title'>{experience.title} - {experience.company} <a href={experience.website} target='_blank' rel='noreferrer'><i class="fa-solid fa-arrow-up-right-from-square"></i></a></h3>
                            <ul className='experience--description'>
                                {experience.description.map((desc, index) => (
                                    <li key={index}>{desc}</li>
                                ))}
                                <li>
                                    Key Achievements:
                                    <ul>
                                        {experience.achievements.map((achievement, index) => (
                                            <li key={index}>{achievement}</li>
                                        ))}
                                    </ul>
                                </li>
                            </ul>
                            <div className='experience--skills skill-tags'>
                                <ul>
                                    {experience.skills.map((skill, index) => (
                                        <li key={index}>{skill}</li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </a>
                ))}
            </div>
            <div className='experience-resume'>
                <h3>Checkout My </h3>
                <h3><a href="/Hanumanthu Resume.pdf" target="_blank" className="experience-resume-link btn">Resume</a> </h3>
            </div>
        </section>
    );
}
