import React from 'react';
import './App.css';
import NavBar from './components/NavBar';
import Home from './components/Home';
import Expertise from './components/Expertise';
import Projects from './components/Projects';
import Experience from './components/Experience';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {

  React.useEffect(() => {
    const customCursor = document.querySelector('.custom-cursor');

    const handleMouseMove = (e) => {
      customCursor.style.transform = `translate(${e.clientX - customCursor.clientWidth / 2}px, ${e.clientY - customCursor.clientHeight / 2}px)`;
    };

    const handleMouseEnter = () => {
      customCursor.classList.add('cursor-pointer');
    };

    const handleMouseLeave = () => {
      customCursor.classList.remove('cursor-pointer');
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.querySelectorAll('a, button, .btn').forEach((el) => {
      el.addEventListener('mouseenter', handleMouseEnter);
      el.addEventListener('mouseleave', handleMouseLeave);
    });

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.querySelectorAll('a, button, .btn').forEach((el) => {
        el.removeEventListener('mouseenter', handleMouseEnter);
        el.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);


  return (
    <div className="Container">
      <div className="custom-cursor">
        <div className="cursor-dot"></div>
      </div>
      <NavBar />
      <main>
        <Home />
        <Expertise />
        <Projects />
        <Experience />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
