import React from 'react';

export default function Footer() {
    return (
        <footer className='page-footer'>
            <div className='mobile-social-list'>
                <ul>
                    <li><a href="https://www.github.com/hsmgowtham" target='_blank' rel='noreferrer'><i class="fa-brands fa-github"></i></a></li>
                    <li><a href="https://www.linkedin.com/in/hsmgowtham" target='_blank' rel='noreferrer'><i class="fa-brands fa-linkedin"></i></a></li>
                </ul>
            </div>
            Designed & Built by <i>Gowtham Hanumanthu</i> using ReactJS
        </footer>
    )
}