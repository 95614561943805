import React from "react";

export default function Home() {

    return (
        <section id="home">
            <p className="highlight">Hi, my name is</p>
            <h1>Gowtham Hanumanthu.</h1>
            <h2 className="highlight">Software & Data Engineer</h2>
            {/* <p>I build efficient software and data engineering solutions, specializing in high-performance systems, data workflows, and scalable applications.
                Currently, I’m passionate about developing scalable data engineering applications, while continuing to create impactful software engineering solutions.
            </p> */}
            {/* <p>I build efficient software and data engineering solutions, specializing in high-performance systems, data workflows, and scalable applications.</p> */}
            <p>I like making applications <span className="highlight">robust with best practices.</span></p>
        </section>
    )
}