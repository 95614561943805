const experienceData = [
    {
        "id": "1",
        "timeline": "Feb - Jul 2023",
        "title": "Software Engineer",
        "company": "Contact Point 360",
        "description": [
            "Developed and optimized an Email Alert System using Django Rest Framework for HR Software, enabling personalized email notifications for employment-lifecycle-related events",
            "Enhanced cross-time-zone support with Celery Beat for efficient email scheduling and real-time event handling.",
            "Managed email alert configurations, schedules, and trigger event logs for enhanced system reliability.",
            "Diagnosed and resolved complex bugs, enhancing system stability and overall user experience.",
        ],
        "achievements": [
            "Recognized for robust implementation, enhancing user engagement and satisfaction."
        ],
        "skills": ["Python", "Django", "RestAPI", "Django Rest Framework", "PostgreSQL", "Celery", "Linux"],
        "website": "https://contactpoint360.com/",
    },
    {
        "id": "2",
        "timeline": "2021 - 2023",
        "title": "Associate Implementation Engineer",
        "company": "Symphony Talent India",
        "description": [
            "Reduced configuration time for ST product CRM by over 95% by implementing a RESTful API server using Flask, Python, SQL, and AWS.",
            "Designed code architecture for core features, including 'User Analytics, Logging, and SQL transaction Models'.",
            "Led sprint planning meetings and managed tasks for a 3-person project team, addressing various challenges.",
            "Developed and updated career sites for high-profile US clients such as IBM, Cleveland Clinic, The Home Depot, among others.",
        ],
        "achievements": [
            "The applications reduced developer efforts, enabling streamlined product setup for various clients in a short time, and earned recognition within the organization.",
            "Won 3rd prize in the ST Idea Hackathon."
        ],
        "skills": ["Python", "Flask", "SQL", "Wordpress", "AWS EC2", "AWS VPC", "AWS Elastic Beanstalk", "JQuery", "HTML5", "CSS3",],
        "website": "https://www.symphonytalent.com/",
    },


]

export default experienceData;